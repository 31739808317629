@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

@font-face {
  font-family: FuturaBold;
  src: url("./fonts/Futura-Bold.otf") format("opentype");
  font-weight: normal;
}

.App {
  text-align: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

body {
  background-color: black;
}

.mobile-artists-wrapper {
  display: none;
}

.mutualfriends-logo {
  margin: auto;
  margin-bottom: 0;
  max-width: 37.5vw;
}

.artists-header {
  color: white;
  margin: 0;
}

.artists-link {
  text-decoration: none;
  color: white;
}

.artists-link:hover {
  text-decoration: underline;
}

.social-links-wrapper {
  height: fit-content;
  display: flex;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.artists-wrapper {
  margin-top: 2.5vh;
  font-family: "FuturaBold";
}

.coming-soon-wrapper {
  color: white;
  font-family: "FuturaBold";
  margin: auto;
  margin-top: 0;
  display: flex;
}

.artists-links-wrapper {
  margin-top: 2.5vh;
}

.social-icon {
  max-width: 40px;
  filter: brightness(0) invert(1);
}

.social-icon:hover {
  cursor: pointer;
}

.social-icon-wrapper {
  margin: auto;
}

.mobile-artists-wrapper {
  display: none;
}

@media screen and (max-width: 770px) {
  .coming-soon-wrapper {
    font-size: 14px;
  }

  .mutualfriends-logo {
    max-width: 65vw;
  }

  .social-icon {
    max-width: 30px;
  }

  .artists-wrapper {
    display: none;
  }

  .social-links-wrapper {
    width: 60vw;
    margin-bottom: 7vh;
  }

  .mobile-artists-wrapper {
    display: flex;
    margin-top: 5vh;
    font-family: "FuturaBold";
    flex-direction: column;
  }

  .mobile-artists-links-wrapper {
    display: flex;
    flex-direction: column;
  }

  .mobile-artists-link {
    text-decoration: none;
    color: white;
    width: fit-content;
    margin: auto;
  }

  .mobile-artists-link:hover {
    text-decoration: underline;
  }
}
